import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Starting to build your product",
  "date": "2021-12-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Building your product`}</h1>
    <p>{`Guidance on how to build and do initial product validation to find
successful product/market fit`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      